<template>
  <ZMainPage type="card">
    <div class="info-box p-2 mb-2">
      <div class="info-table">
        <el-row class="info-line" v-for="(item, index) in list" :key="index">
          <el-col class="item" :span="8" v-for="(child, _cid) in item" :key="_cid">
            <div class="flex">
              <div class="label">{{ child.label }}</div>
              <div class="value">{{ child.value }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="info-box p-2">
      <el-table :data="listData" style="width: 100%" size="small" v-loading="loading">
        <el-table-column prop="student_no" label="学号"> </el-table-column>
        <el-table-column prop="student_name" label="学生姓名"> </el-table-column>
        <el-table-column prop="status" label="是否完成辅导">
          <template slot-scope="scope">{{ scope.row.status === 1 ? '已完成' : '未完成' }}</template>
        </el-table-column>
        <el-table-column prop="coach_content" label="辅导内容字数"> </el-table-column>
        <el-table-column prop="org_teacher_name" label="辅导教师"> </el-table-column>
        <el-table-column prop="coach_time" label="辅导完成时间"> </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <template v-if="scope.row.status">
              <el-button @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </template>
  </ZMainPage>
</template>
<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  name: 'Details',
  data() {
    return {
      list: [],
      listData: [],
      loading: false
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id
      this.loading = true
      this.$_axios2
        .get('/api/teacher/moral-educationController/pc-details?id=' + id)
        .then(res => {
          const {
            data: { data }
          } = res
          this.listData = data.student
          this.list = [
            [
              { label: '行政班', value: data.class_name },
              { label: '年级', value: data.grade_name },
              { label: '校区', value: data.school_name },
            ],
            [
              { label: '班主任', value: data.teacher_name },
              { label: '完成率', value: data.complete_rate + '%' },
              { label: '起始日期', value: data.day_describe },
            ],
            [
              { label: '当前班级人数', value: data.student_count },
              { label: '辅导内容平均字数', value: data.avg_coach_content },
              { label: '', value: '' },
              // { label: '监督内容平均字数', value: data.avg_supervise },
            ],
          ]
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleDetail(row) {
      this.$router.push('/moral/teacher-work/moral-one-to-one-record/detail?id=' + row.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.info {
  &-box {
    background-color: #fff;
    border-radius: 4rem;
  }
  &-table {
    border: 1px solid #DBDDE4;
  }
  &-line {
    line-height: 38rem;
    font-size: 16rem;
    &:not(:last-child) {
      border-bottom: 1px solid #DBDDE4;
    }
    .item:not(:last-child) {
      border-right: 1px solid #DBDDE4;
    }
    .label {
      min-height: 38rem;
      padding-left: 24rem;
      color: #222;
      flex: 2;
      background-color: #F6F9FF;
      border-right: 1px solid #DBDDE4;
    }
    .value {
      min-height: 38rem;
      padding-left: 30rem;
      color: #3C3C3C;
      flex: 3;
    }
  }
}

</style>
